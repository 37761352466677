"use client";
import React, { RefObject } from "react";
import * as RadixSelect from "@radix-ui/react-select";
import Icon from "./icon";
import Button, { BUTTON_VARIANCE } from "./button";
import list from "./list.module.css";
import * as ScrollArea from "@radix-ui/react-scroll-area";
import { useIsTouchDevice } from "@/utils/hooks/device";

// @TODO: clean this up and convert to cva. reduce code duplication.

export interface Option {
  value: string;
  label: string | JSX.Element;
  icon?: string;
  count?: number;
}

export type SelectVariant =
  | "default"
  | "team"
  | "card"
  | "form"
  | "sport"
  | "team-sm"
  | "pick";
export interface SelectProps extends RadixSelect.SelectProps {
  label: string;
  options: Option[];
  variant?: SelectVariant;
  align?: "start" | "center" | "end";
  icon?: string;
  height?: number;
  neutral?: boolean;
  dark?: boolean;
  block?: boolean;
  setSelecting?: (selecting: boolean) => void;
}

function Select(props: SelectProps) {
  const ref = React.useRef<HTMLDivElement>(null);
  const isTouchDevice = useIsTouchDevice();
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState<string | undefined>(
    props.value || undefined
  );

  const [touchStartY, setTouchStartY] = React.useState(0);
  const [isSwiping, setIsSwiping] = React.useState(false);

  React.useEffect(() => {
    setValue(props.value);
  }, [props.value]);  
  
  React.useEffect(() => {
    if (props.setSelecting) {
      props.setSelecting(open);
    }
  }, [open, props]);

  const handleTouchStart = (e: any) => {
    setTouchStartY(e.touches[0].clientY);
    setIsSwiping(false);
    e.preventDefault();
  };

  const handleTouchMove = (e: any) => {
    const touchCurrentY = e.touches[0].clientY;
    if (Math.abs(touchCurrentY - touchStartY) > 10) {
      setIsSwiping(true);
    }
  };

  const handleTouchEnd = (e: any) => {
    if (!isSwiping) {
      toggleOpen(e);
    }
    setIsSwiping(false);
  };

  function handleValueChange(value: string) {
    setValue(value);

    if (props.onValueChange) {
      props.onValueChange(value);
    }
    setOpen(false);
  }

  function toggleOpen(e: any) {
    e.preventDefault();
    setOpen((prev) => !prev);
  }

  function handleClose(e: any) {
    e.preventDefault();
    setOpen(false);
  }

  function handleClick(e: any) {
    e.preventDefault();
    handleValueChange(e.currentTarget.dataset.value);
  }

  function useOutsideTouch(
    ref: RefObject<HTMLElement>,
    setIsSwiping: (isSwiping: boolean) => void,
    setTouchStartY: (y: number) => void
  ): void {
    React.useEffect(() => {
      const handleTouchStart = (event: TouchEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          setTouchStartY(event.touches[0].clientY);
          setIsSwiping(false);
        }
      };

      const handleTouchMove = (event: TouchEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          const touchCurrentY = event.touches[0].clientY;
          if (Math.abs(touchCurrentY - touchStartY) > 10) {
            setIsSwiping(true);
          }
        }
      };

      const handleTouchEnd = (event: TouchEvent) => {
        if (
          ref.current &&
          !ref.current.contains(event.target as Node) &&
          !isSwiping
        ) {
        }
      };

      document.addEventListener("touchstart", handleTouchStart);
      document.addEventListener("touchmove", handleTouchMove);
      document.addEventListener("touchend", handleTouchEnd);

      return () => {
        document.removeEventListener("touchstart", handleTouchStart);
        document.removeEventListener("touchmove", handleTouchMove);
        document.removeEventListener("touchend", handleTouchEnd);
      };
    }, [ref, setIsSwiping, setTouchStartY]);
  }
  useOutsideTouch(ref, setIsSwiping, setTouchStartY);

  const content: JSX.Element = (
    <RadixSelect.Content
      ref={ref}
      position="popper"
      align={props.align}
      sideOffset={8}
      collisionPadding={10}
      className={`${list.root} select-content`}
      data-variant={props.variant}
      onPointerDownOutside={handleClose}
      onCloseAutoFocus={handleClose}
      onEscapeKeyDown={handleClose}
      onTouchMove={handleTouchMove}
      onTouchStart={handleTouchStart}
      onDrag={(e) => e.preventDefault()}
    >
      <RadixSelect.Viewport className="select-none">
        <ScrollArea.Root
          className="p-2"
          style={{
            height: props.height ? `${props.height}rem` : "100%",
            maxHeight: "100vh",
          }}
        >
          <ScrollArea.Viewport className="w-full h-full">
            {props.options.map((option, i) => (
              <RadixSelect.Item
                key={i}
                value={option.value}
                className={list.item}
                onClick={!isTouchDevice ? handleClick : undefined}
                onTouchEnd={
                  isTouchDevice && !isSwiping ? handleClick : undefined
                }
                data-value={option.value}
              >
                <RadixSelect.ItemText>
                  <div data-variant={props.variant} className={list.text}>
                    {option.icon && <Icon name={option.icon} size="md"></Icon>}
                    {option.label}{" "}
                    {option.count !== undefined && option.count > 0
                      ? `(${option.count})`
                      : ""}
                  </div>
                </RadixSelect.ItemText>
                <RadixSelect.ItemIndicator className={list.indicator}>
                  <Icon
                    name="checkmark"
                    size="md"
                    className="transition-none"
                  ></Icon>
                </RadixSelect.ItemIndicator>
              </RadixSelect.Item>
            ))}
          </ScrollArea.Viewport>
          <ScrollArea.Scrollbar
            className="flex select-none touch-none p-0.5 bg-light transition-colors duration-[160ms] ease-out hover:bg-blackA5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col data-[orientation=horizontal]:h-2.5"
            orientation="vertical"
          >
            <ScrollArea.Thumb className="flex-1 bg-secondary rounded-[10px] relative before:content-[''] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:w-full before:h-full before:min-w-[44px] before:min-h-[44px]" />
          </ScrollArea.Scrollbar>
        </ScrollArea.Root>
      </RadixSelect.Viewport>
    </RadixSelect.Content>
  );

  return (
    <>
      {open && (
        <div className="fixed inset-0 z-[50]" onClick={toggleOpen}></div>
      )}
      {/* @TODO: change these to button modules, remove scroll area and variance */}
      <RadixSelect.Root
        {...props}
        value={value}
        onValueChange={handleValueChange}
        open={open}
      >
        {props.variant === "default" || !props.variant ? (
          <RadixSelect.Trigger
            className={`select-trigger btn btn-md btn-filled-secondary !justify-between ${
              props.block ? "w-full" : "w-auto"
            }`}
            aria-label={props.label}
            onClick={!isTouchDevice ? toggleOpen : undefined}
            onTouchEnd={isTouchDevice ? handleTouchEnd : undefined}
            onTouchMove={handleTouchMove}
            onTouchStart={handleTouchStart}
            onDrag={(e) => e.preventDefault()}
          >
            {props.icon ? (
              <div className={list.text}>
                <Icon name={props.icon} size="md" className="fill-dark" />
                <RadixSelect.Value
                  placeholder={props.label}
                  className={list.text}
                />
              </div>
            ) : (
              <RadixSelect.Value
                placeholder={props.label}
                className={list.text}
              />
            )}
            <Icon
              name="caret-down"
              size="md"
              className={`shrink-0 fill-primary-200 transition-transform duration-[50ms] ease-in-out group-hover:fill-dark group-data-[state=open]:-rotate-180`}
            ></Icon>
          </RadixSelect.Trigger>
        ) : props.variant === "team" ? (
          <RadixSelect.Trigger
            className={`select-trigger ${BUTTON_VARIANCE.base} ${BUTTON_VARIANCE.standard.base} ${BUTTON_VARIANCE.standard.size.md} hover:bg-blue hover:text-white bg-surface-600 font-medium text-secondary-400 gap-4 outline-none group`}
            aria-label={props.label}
            onClick={!isTouchDevice ? toggleOpen : undefined}
            onTouchEnd={isTouchDevice ? handleTouchEnd : undefined}
            onTouchMove={handleTouchMove}
            onTouchStart={handleTouchStart}
            onDrag={(e) => e.preventDefault()}
          >
            {props.icon ? (
              <div className="flex flex-row gap-2">
                <Icon
                  name={props.icon}
                  size="md"
                  className="fill-secondary-400"
                />
                <RadixSelect.Value
                  placeholder={props.label}
                  className="text-secondary"
                />
              </div>
            ) : (
              <RadixSelect.Value
                placeholder={props.label}
                className="text-secondary"
              />
            )}
            <Icon
              name="caret-down"
              size="md"
              className={`fill-blue transition-transform duration-[50ms] ease-in-out group-hover:fill-light group-data-[state=open]:-rotate-180`}
            ></Icon>
          </RadixSelect.Trigger>
        ) : props.variant === "card" ? (
          <RadixSelect.Trigger
            className={`group select-trigger  ${BUTTON_VARIANCE.base} ${BUTTON_VARIANCE.standard.base} ${BUTTON_VARIANCE.standard.size.xs} py-1 px-1 gap-1 desktop-grid:pl-3 desktop-grid:pr-2 desktop-grid:gap-2 rounded-full hover:bg-surface-600 outline-none ${list.trigger}`}
            aria-label={props.label}
            data-variant="card"
            data-dark={`${props.dark}`}
            onClick={!isTouchDevice ? toggleOpen : undefined}
            onTouchEnd={isTouchDevice ? handleTouchEnd : undefined}
            onTouchMove={handleTouchMove}
            onTouchStart={handleTouchStart}
            onDrag={(e) => e.preventDefault()}
          >
            <RadixSelect.Value
              placeholder={props.label}
              className="text-secondary"
            />
            <Icon
              name="caret-down"
              size="xs"
              className="shrink-0 transition-transform duration-[50ms] ease-in-out group-data-[state=open]:-rotate-180"
            ></Icon>
          </RadixSelect.Trigger>
        ) : props.variant === "team-sm" ? (
          <RadixSelect.Trigger
            className={`group select-trigger ${list.trigger} ${BUTTON_VARIANCE.base} ${BUTTON_VARIANCE.standard.size.xs} py-1 pl-3 pr-2 rounded-full gap-1 bg-[rgba(21,_23,_27,_0.25)] hover:bg-[rgba(21,_23,_27,_0.5)] outline-none text-white border-[1.5px] border-white`}
            aria-label={props.label}
            data-variant={props.variant}
            onClick={!isTouchDevice ? toggleOpen : undefined}
            onTouchEnd={isTouchDevice ? handleTouchEnd : undefined}
            onTouchMove={handleTouchMove}
            onTouchStart={handleTouchStart}
            onDrag={(e) => e.preventDefault()}
          >
            <RadixSelect.Value
              placeholder={props.label}
              className="text-white"
            />
            <Icon
              name="carom-down"
              size="xs"
              className="fill-white transition-transform duration-[50ms] ease-in-out group-data-[state=open]:-rotate-180"
            ></Icon>
          </RadixSelect.Trigger>
        ) : props.variant === "form" ? (
          <RadixSelect.Trigger
            className={`group select-trigger ${BUTTON_VARIANCE.base} ${BUTTON_VARIANCE.standard.base}  py-3 px-4 gap-2 border text-dark text-input h-[3.25rem] border-input-grey hover:border-dark focus:border-dark outline-none`}
            aria-label={props.label}
            style={{ pointerEvents: open ? "none" : "auto" }}
            onClick={!isTouchDevice ? toggleOpen : undefined}
            onTouchEnd={isTouchDevice ? handleTouchEnd : undefined}
            onTouchMove={handleTouchMove}
            onTouchStart={handleTouchStart}
            onDrag={(e) => e.preventDefault()}
          >
            <div className="text-dark group-data-[placeholder]:text-secondary">
              <RadixSelect.Value
                placeholder={props.label}
                className="text-dark"
              />
            </div>
            <Icon
              name="caret-down"
              size="md"
              className="shrink-0 transition-transform duration-[50ms] ease-in-out group-data-[state=open]:-rotate-180"
            ></Icon>
          </RadixSelect.Trigger>
        ) : props.variant === "sport" ? (
          <RadixSelect.Trigger
            className={`group select-trigger ${BUTTON_VARIANCE.base} !gap-1 uppercase text-white outline-none py-1 ${list.trigger}`}
            data-variant={props.variant}
            aria-label={props.label}
            onClick={!isTouchDevice ? toggleOpen : undefined}
            onTouchEnd={isTouchDevice ? handleTouchEnd : undefined}
            onTouchMove={handleTouchMove}
            onTouchStart={handleTouchStart}
            onDrag={(e) => e.preventDefault()}
          >
            <RadixSelect.Value placeholder={props.label} />
            <Icon
              name="arrow-button-down"
              size="md"
              className={`fill-white transition-all duration-[50ms] ease-in-out group-data-[state=open]:-rotate-180 `}
            ></Icon>
          </RadixSelect.Trigger>
        ) : (
          props.variant === "pick" && (
            <>
              <RadixSelect.Trigger
                className={`select-trigger ${BUTTON_VARIANCE.base} ${
                  BUTTON_VARIANCE.standard.base
                } ${BUTTON_VARIANCE.standard.size.md} ${
                  BUTTON_VARIANCE.standard.color.filled.secondary
                } font-medium ${
                  props.neutral ? "bg-surface-600" : "text-dark"
                } gap-4 outline-none group w-full flex`}
                aria-label={props.label}
                onClick={!isTouchDevice ? toggleOpen : undefined}
                onTouchEnd={isTouchDevice ? handleTouchEnd : undefined}
                onTouchMove={handleTouchMove}
                onTouchStart={handleTouchStart}
                onDrag={(e) => e.preventDefault()}
              >
                <RadixSelect.Value
                  placeholder={props.label}
                  className={list.text}
                />

                <Icon
                  name="caret-down"
                  size="md"
                  className={`shrink-0 fill-secondary-300 transition-transform duration-[50ms] ease-in-out group-hover:fill-dark group-data-[state=open]:-rotate-180`}
                ></Icon>
              </RadixSelect.Trigger>
            </>
          )
        )}
        {props.variant === "pick" ? (
          <>{content}</>
        ) : (
          <RadixSelect.Portal>{content}</RadixSelect.Portal>
        )}
      </RadixSelect.Root>
    </>
  );
}

export default Select;
